/**
 * File:
 *   reducer-utils.js
 * 
 * Description:
 *   Redux reducer utilities
 */

import {
  RestConstants,
} from '../../rest';

const LOG_TAG = 'reducer-utils.js';

/**
 * Convert a route to a camel cased name.
 * @return {String} The route
 */
export function convertRouteToCamelCasedName(route) {
  let routeName = route;
  // Remove the leading slash.
  //routeName = routeName.substring(1);
  // Replace underscores with spaces.
  //routeName = routeName.toLowerCase().replace(/_/g, ' ');
  // Capitalize the first letter of each word.
  //routeName = routeName.replace(/\b\w/g, (l) => l.toUpperCase());
  //routeName = routeName.replace(/\s/g, '');
  //routeName = routeName.charAt(0).toLowerCase() + routeName.substring(1);
  return routeName;
}
