/**
 * File:
 *   imursif-request-metadata-reducer.js
 */

import * as ReducerUtils from './reducer-utils';

/**
 * Get the default state.
 */
// function getDefaultState() {
//   let intlState = {};
//   for (var key of Object.keys(RestConstants.Requests)) {
//     let keyNameCamelCase = ReducerUtils.convertRouteToCamelCasedName(key);
//     let node = RestConstants.Requests[keyNameCamelCase];
//     if (typeof node !== 'function') {
//       intlState[node] = { };
//     }
//   }
//   return intlState;
// }

let requestNameCamelCase = undefined;

/**
 * The reducer function
 * @param {Object} state     The state passed by Redux; Note: We give it an
 *                           initial value so we don't have to check for
 *                           certain keys throughout the app.
 * @param {Object} action    The action passed by Redux
 */
const reducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_MADE_AT':
      requestNameCamelCase = ReducerUtils.convertRouteToCamelCasedName(
          action.request);
      return {
        ...state,
        [requestNameCamelCase]: {
          ...state[requestNameCamelCase],
          madeAt: action.madeAt,
          wasSuccessful: action.wasSuccessful,
        }
      };
    case 'SET_IS_CURRENTLY_BEING_MADE':
      // Getting this error here for some reason:
      //    ReferenceError: can't access lexical declaration 'requestNameCamelCase' before initialization
      
      requestNameCamelCase = ReducerUtils.convertRouteToCamelCasedName(
          action.request);
      return {
        ...state,
        [requestNameCamelCase]: {
          ...state[requestNameCamelCase],
          isCurrentlyBeingMade: action.isCurrentlyBeingMade,
        }
      };
    case 'CLEAR_MADE_AT':
      // Getting this error here for some reason:
      //    ReferenceError: can't access lexical declaration 'requestNameCamelCase' before initialization
      
      requestNameCamelCase = ReducerUtils.convertRouteToCamelCasedName(
          action.request);
      return {
        ...state,
        [requestNameCamelCase]: {
          ...state[requestNameCamelCase],
          madeAt: null,
          wasSuccessful: null,
        }
      };
    default:
      return state;
  }
};

export default reducer;
