import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Link } from "react-router-dom";
import AsyncStorageKeys from './database/async-storage-keys';
import ReduxStore from './database/redux-store';
import './App.css';
// import VideoPlayer from './routes/video-player/video-player-component.js';
import VideoPlayer from './routes/video-player/index.js';

class App extends Component {
  render() {
    return (
      <Provider
        store={ReduxStore}>
        <VideoPlayer />
      </Provider>
    );
  }
}

export default App;