/**
 * File:
 *   imursif-request-data-reducer-actions.js
 */
 
const setData = (request, data) => {
  return {
    type: 'SET_DATA',
    request: request,
    data: data,
  };
};

const clearData = (request) => {
  return {
    type: 'CLEAR_DATA',
    request: request,
  };
};

export default {
  setData,
  clearData,
};
