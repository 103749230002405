/**
 * File:
 *   uri-utils.js
 * 
 * Description:
 *   URI utilities
 */

/**
 * Get the value of a parameter from a URI parameter string.
 * @param {Object} location    The react-router-dom location
 * @param {String} paramName   The name of the parameter
 */
export function getQueryParamValue(location, paramName) {
  var query = location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == paramName) {
          return decodeURIComponent(pair[1]);
      }
  }
  return undefined;
}

export default {
  getQueryParamValue,
};
