/**
 * File:
 *   payment-part-one-component.js
 * 
 * Description:
 *   The payment - part one component
 */

import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Link, useLocation, useNavigate
} from "react-router-dom";
import ReduxStore from '../../database/redux-store';
import * as URIUtils from '../../utils/uri-utils';
// import {Spacer} from '../global';
// import Dialog from '../global/dialog';
// import Footer from '../global/footer';
// import Header from '../global/header';
import './payment-part-one-component-styles.css';


var emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

const PaymentPartOneScreen = (props) => {
  const navigate = useNavigate();
  let location = useLocation();
  const [name, setName] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const [county, setCounty] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [isAlertShow, setAlertShow] = useState(false);
  const [isAlertMessage, setAlertMessage] = useState("");

  const [isAddressSame, setAddressSame] = useState(false);

  const [id,setId] = useState(localStorage.getItem("id"))

  const [videoId, serVideoId] = useState(0);

  useEffect(() => {
    let currCartItemsArray = [];
    if (typeof ReduxStore.getState().cache.checkout.cartItems !== 'undefined') {
      for (const [key, value] of Object.entries(ReduxStore.getState().cache.checkout.cartItems)) {
        if (typeof value !== 'undefined' && value !== null) {
          currCartItemsArray.push(value);
        }
      }
    }
    if (currCartItemsArray.length > 0) {
      for (var i = 0; i < currCartItemsArray.length; i++) {
        let newId = currCartItemsArray[i].videoId
        serVideoId(newId)
      }
    }
  }, []);

  let areAllFieldsFilledOut = false;
  if (
    typeof name !== 'undefined' &&
    // typeof streetAddress !== 'undefined' &&
    // typeof city !== 'undefined' &&
    // typeof country !== 'undefined' &&
    // typeof county !== 'undefined' &&
    // typeof postalCode !== 'undefined' &&
    typeof emailAddress !== 'undefined'
  ) {
    areAllFieldsFilledOut = true;
  }

  function createParameterString() {
    let paramString = '?total=' + URIUtils.getQueryParamValue(location, 'total');
    paramString += '&name=' + name
    paramString += '&streetAddress=' + streetAddress;
    paramString += '&city=' + city;
    paramString += '&country=' + country;
    paramString += '&county=' + county;
    paramString += '&postalCode=' + postalCode;
    paramString += '&emailAddress=' + emailAddress;
    paramString += '&isAddressSame=' + isAddressSame;
    return paramString;
  }

  return (
    <div
      className='screen-conainer-payment-part-one'>
      <div className='heading-grid'>
        <Link className=' mr-2' style={{ textDecoration: 'none', color: 'white' }} to={`/video/${id}`}><i class="fa-solid fa-chevron-left"></i></Link>
        <h4 className='text-center text-white  mt-2'>Shipping</h4>
      </div>
      <div
        className='main-content header-checkout'>
        <div
          className='label-text'>
          Shipping address
        </div>
        <form
          className='main-input'>
          <input className='main-input-field' type="text" name="name" placeholder="First and last name" defaultValue={name} onChange={(event) => {
            setName(event.target.value);
          }} />
          <label>First and Last name</label>
        </form>
        <form
          className='main-input'>
          <input className='main-input-field' type="text" name="name" placeholder="Street" defaultValue={streetAddress} onChange={(event) => {
            setStreetAddress(event.target.value);
          }} />
          <label>Street</label>
        </form>
        <form
          className='main-input'>
          <input className='main-input-field' type="text" name="name" placeholder="City" defaultValue={city} onChange={(event) => {
            setCity(event.target.value);
          }} />
          <label>City</label>
        </form>
        <form
          className='main-input'>
          <input className='main-input-field' type="text" name="name" placeholder="County" defaultValue={county} onChange={(event) => {
            setCounty(event.target.value);
          }} />
          <label>County</label>
        </form>
        <form
          className='main-input'>
          <input className='main-input-field' type="text" name="name" placeholder="Post-Code" defaultValue={postalCode} onChange={(event) => {
            setPostalCode(event.target.value);
          }} />
          <label>Post-Code</label>
        </form>
        <form
          className='main-input'>
          <input className='main-input-field' type="text" name="name" placeholder="Country" defaultValue={country} onChange={(event) => {
            setCountry(event.target.value);
          }} />
          <label>Country</label>
        </form>
        <form
          className='main-input-dt'>
          <input onChange={(e) => {
            setAddressSame(e.target.checked)
          }} className='check' type="checkbox" name="name" placeholder="" />
          <label className='mb-0'>Is billing address same as shipping address ?</label>
        </form>
        <div
          className='label-text my-4'>
          Contact information
        </div>
        <form
          className='main-input'>
          <input className='main-input-field' type="text" name="name" placeholder="Email" defaultValue={emailAddress} onChange={(event) => {
            setEmailAddress(event.target.value);
          }} />
          <label>Email</label>
        </form>
      </div>
      <div
        className='bottom-section-options'>
        <h5
          className='total-text text-white text-center mb-2'>
          Total: £{URIUtils.getQueryParamValue(location, 'total')}
        </h5>
       
        {
          !areAllFieldsFilledOut && (
            <button
              className='continue btn btn-red-color'
              onClick={() => {
                alert('Please fill out all required fields.')
              }}>
              Continue
            </button>
          ) || (
            <button
              className='continue btn btn-red-color'
              onClick={() => {

                if (typeof name === 'undefined' || name === '' ||
                  typeof streetAddress === 'undefined' || streetAddress === '' ||
                  typeof city === 'undefined' || city === '' ||
                  typeof postalCode === 'undefined' || postalCode === '' ||
                  typeof country === 'undefined' || country === '' ||
                  typeof county === 'undefined' || county === '' ||
                  typeof emailAddress === 'undefined' || emailAddress === '') {

                  // alert('Please fill out all fields.');
                  setAlertShow(true)
                  setAlertMessage("Please fill out all required fields.")
                  return;

                }
                else if (!emailPattern.test(emailAddress)) {
                  // alert('Please enter valid email.');
                  setAlertShow(true)
                  setAlertMessage("Please enter valid email.")
                  return;
                } else {
                  navigate({
                    pathname: '/payment-part-two',
                    search: createParameterString(),
                    hash: '#hash',
                    state: null,
                  });
                }
              }}>
              Continue
            </button>
          )
        }
      </div>
      {
        isAlertShow == true &&
        <div className='modal-dialog-dtff'>
          <div class="dialog success">
            <button id="success" onClick={() => {
              setAlertShow(false)
            }} class="close">x</button>
            <p>{isAlertMessage}</p>
          </div>
        </div>
      }
    </div>
  );
};

export default PaymentPartOneScreen;
