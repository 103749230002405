import React from 'react';
import ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import App from "./App";
import CheckoutPartOne from "./routes/checkout-part-one/checkout-part-one-component.js";
import PaymentPartOne from "./routes/payment-part-one/payment-part-one-component.js";
import PaymentPartTwo from "./routes/payment-part-two/payment-part-two-component.js";

import VideoPlayer from './routes/video-player/index.js';
import VideoPlayerDesktop from './routes/video-player/new-video.js';


import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import reduxStore from './database/redux-store';

const NavbarRouter = () => {


  return (
    <Provider store={reduxStore}>
      <Routes>
        {/* <Route path="/" element={<App />} /> */}
        <Route path="embed/:id" element={<VideoPlayer />} />
        <Route path="/:id" element={<VideoPlayerDesktop />} />
        <Route path="checkout-part-one" element={<CheckoutPartOne />} />
        <Route path="payment-part-one" element={<PaymentPartOne />} />
        <Route path="payment-part-two" element={<PaymentPartTwo />} />

      </Routes>
    </Provider>
  );
};

ReactDOM.render(
  <BrowserRouter>
    <NavbarRouter />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
