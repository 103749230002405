/**
 * File:
 *   video-player-screen.js
 * 
 * Description:
 *   The video player screen
 */

import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useNavigate
} from "react-router-dom";

import ReducerActions from '../../database/actions/reducer-actions';
import ReduxStore from '../../database/redux-store';

import DeleteIcon from '../../assets/images/delete.svg'

import './checkout-part-one-component-styles.css';

const CheckoutPartOneScreen = (props) => {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState();
  const [total, setTotal] = useState(0);
  const [videoId, serVideoId] = useState(0);
  const [_, forceUpdate] = useState(0)
  const location = useLocation()
  const [totalItems, setTotalItems] = useState(0)


  useEffect(() => {
    let currCartItemsArray = [];
    if (typeof ReduxStore.getState().cache.checkout.cartItems !== 'undefined') {
      let total = 0
      for (const [key, value] of Object.entries(ReduxStore.getState().cache.checkout.cartItems)) {
        if (typeof value !== 'undefined' && value !== null) {
          currCartItemsArray.push(value);
          total += value.quantity
        }
      }
      setTotalItems(total)
    }

  let id = ReduxStore.getState().cache.checkout.videoId
  console.log("id===",id)

    setCartItems(currCartItemsArray);
    updateTotal(currCartItemsArray);


    if (currCartItemsArray.length > 0) {
      for (var i = 0; i < currCartItemsArray.length; i++) {
        let newId = currCartItemsArray[i].videoId
        serVideoId(newId)
      }

    }
    function onPageReload(e) {
      e.preventDefault();
      e.returnValue = "";
    };

    window.addEventListener('beforeunload', onPageReload);
    return () => {
      window.removeEventListener('beforeunload', onPageReload);
    };
  }, []);


  //update the total amount in real time on adding or removing quantity of products.
  function updateTotal(cartItems) {
    let _total = 0;
    for (const prodVariant of cartItems) {
      _total += parseFloat(prodVariant.variant.price * prodVariant.quantity);
    }

    setTotal(_total);
  }

  let listItems;


  function updateQuantity() {
    let total = 0
    cartItems.forEach(element => {
      total += element.quantity
    });
    setTotalItems(total)
    forceUpdate(Math.random())
  }

  // Cart Items List , Add or remove Items or their quantities

  if (typeof cartItems !== 'undefined') {
    listItems = cartItems.map((_cartItem) => {

      function addQuantity() {
        _cartItem.quantity += 1
        forceUpdate(Math.random())

        updateTotal(cartItems)
        updateQuantity()

      }

      function removeQuantity() {
        if (_cartItem.quantity > 1) {
          _cartItem.quantity -= 1
          forceUpdate(Math.random())
          updateTotal(cartItems)
          updateQuantity()
        } else {

          let currCartItems = ReduxStore.getState().cache.checkout.cartItems;
          let newCartItems = {
            ...currCartItems,
            [_cartItem.id]: undefined,
          };

          ReduxStore.dispatch(ReducerActions.Cache.Checkout.setCartItems(newCartItems));

          let array = [];
          for (const [key, value] of Object.entries(newCartItems)) {
            if (typeof value !== 'undefined' && value !== null) {
              array.push(value);
            }
          }
          setCartItems(array);
          updateTotal(array);
          updateQuantity()
        }
      }

      return (
        <div
          className='cartitem'
          key={_cartItem.id.toString()}>
          <img
            style={{ objectFit: 'contain' }}
            src={_cartItem.productImage}
            alt='Image'
          />

          <div>
            <p class="mb-2">{_cartItem.productTitle} £{_cartItem.variant.price}</p>

            <div className="cart-items-list d-flex justify-content-start text-center align-items-center">
              <p className="label-para"> <span class="label-size">{_cartItem.variant.title}</span></p>
              <button onClick={() => removeQuantity()} className="cart-item-buttons">-</button>
              <span className="px-2 pt-1">{_cartItem.quantity}</span>
              <button onClick={() => addQuantity()} className="cart-item-buttons">+</button>
            </div>



          </div>
          <button class="cart-delete" onClick={() => {
            let currCartItems = ReduxStore.getState().cache.checkout.cartItems;
            let newCartItems = {
              ...currCartItems,
              [_cartItem.id]: undefined,
            };

            ReduxStore.dispatch(ReducerActions.Cache.Checkout.setCartItems(newCartItems));

            let array = [];
            for (const [key, value] of Object.entries(newCartItems)) {
              if (typeof value !== 'undefined' && value !== null) {
                array.push(value);
              }
            }
            setCartItems(array);
            updateTotal(array);
          }} >
            <img src={DeleteIcon} />
          </button>
        </div>
      );
    });
  }


  //Navigate to online store checkout --removed form

  const handleNav = () => {
  let storeSubdomain = ReduxStore.getState().cache.checkout.storeSubdomain
    let link = "http://" + storeSubdomain + "/cart/"

    if (typeof ReduxStore.getState().cache.checkout.cartItems !== 'undefined') {
      for (const [key, value] of Object.entries(ReduxStore.getState().cache.checkout.cartItems)) {
        console.log("inside loop");
        if (typeof value !== 'undefined' && value !== null) {

          link = link + value.variant.id + ":" + value.quantity + ","
          // line_items.push({
          //   variant_id: value.variant.id,
          //   quantity: value.quantity
          // })
        }
      }
    }
    link = link.substring(0, link.length - 1)

    link = link + "?storefront=true"
    console.log(link)
    window.open(
      link, '_blank'
    )
  }

  //UI Cart Page
  return (
    <div
      className='screen-conainer-checkout-part-one h-100 px-2'>
      <div className='heading-grid'>
        <Link className=' mr-2' style={{ textDecoration: 'none', color: 'white' }} to={location?.state?.screenName == "embed" ? `/embed/${videoId}` : `/${videoId}`}><i role="button" class="fa-solid fa-chevron-left"></i></Link>
        <h4 className='text-center text-white  mt-2'>Checkout</h4>
      </div>
      {
        typeof cartItems !== 'undefined' && (
          <div
            className='cart-inner-scroll thin-scroll'>
            {listItems}
          </div>
        )
      }
      {
        typeof cartItems !== 'undefined' && cartItems.length > 0 &&
        typeof total !== 'undefined' && (
          <div
            className='bottom-section-options'>
            <div class="d-flex align-items-end justify-content-between text-white py-2 mb-2 bggrey px-2">
              <p class="font-weight-bold mb-0">PRODUCT QUANTITY</p>
              <p class="font-weight-bold mb-0">PRICE</p>
            </div>
            <div class="d-flex align-items-end justify-content-between text-white px-2 ">
              <p class="">{totalItems} item{totalItems > 1 ? 's' : ''}</p>
              <p class="">£{total.toFixed(2)}</p>
            </div>
            <div className='d-flex justify-content-between'>

              <button
                className='btn btn-red-color continue btn-lg'
                onClick={handleNav}
              // onClick={() => {
              //   navigate({
              //     pathname: '/payment-part-one',
              //     search: (typeof total === 'undefined') ? '?sort=name' : '?total=' + total.toString(),
              //     hash: '#hash',
              //     state: null,
              //   });
              // }}

              >
                Continue
              </button>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default CheckoutPartOneScreen;
