/**
 * File:
 *   app-state-reducer-actions.js
 */

const General = {
  
  setCurrentVisibleScreenName: (currentVisibleScreenName) => {
    return {
      type: 'GENERAL_O_CURRENT_VISIBLE_SCREEN_NAME',
      currentVisibleScreenName: currentVisibleScreenName
    };
  },
  
  setCurrentVisibleViewName: (currentVisibleViewName) => {
    return {
      type: 'GENERAL_O_CURRENT_VISIBLE_VIEW_NAME',
      currentVisibleViewName: currentVisibleViewName
    };
  },
  
  User: {
    
    setDoesUserNeedToBeLoggedOut: (doesUserNeedToBeLoggedOut) => {
      return {
        type: 'GENERAL_S_USER_O_DOES_USER_NEED_TO_BE_LOGGED_OUT',
        doesUserNeedToBeLoggedOut: doesUserNeedToBeLoggedOut
      };
    },
    clearAll: () => {
      return {
        type: 'GENERAL_S_USER_O_CLEAR_ALL',
      };
    },
    
  },
  
}

const BackgroundTasks = {
  
  LogIn: {
    
    setIsCheckTokenExpirationBackgroundTaskRunning: (isCheckTokenExpirationBackgroundTaskRunning) => {
      return {
        type:
            'BACKGROUND_TASKS_S_LOG_IN_O_IS_CHECK_TOKEN_EXPIRATION_BACKGROUND_TASK_RUNNING',
        isCheckTokenExpirationBackgroundTaskRunning:
            isCheckTokenExpirationBackgroundTaskRunning
      };
    },
    
  },
  
}

const Screens = {
  
  LogIn: {
    
    setFunctions: (setUpIsBiometricLogInEnabled) => {
      return {
        type: 'SCREENS_S_LOG_IN_O_SET_FUNCTIONS',
        setUpIsBiometricLogInEnabled: setUpIsBiometricLogInEnabled,
      };
    },
    
  },
  
  VideoPlayer: {
  
    setFunctions: (setCartItems, setForceRerenderValue) => {
      return {
        type: 'SCREENS_S_VIDEO_PLAYER_O_SET_FUNCTIONS',
        setCartItems: setCartItems,
        setForceRerenderValue: setForceRerenderValue,
      };
    },
    
  },

}

export default {
  General,
  BackgroundTasks,
  Screens,
};
