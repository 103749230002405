/**
 * File:
 *   payment-part-one-component.js
 * 
 * Description:
 *   The payment - part one component
 */

import * as React from 'react';
import {
  useEffect, useState
} from 'react';
import {
  Link, useLocation, useNavigate
} from "react-router-dom";
// No longer available
// import LoadingOverlay from 'react-loading-overlay';
import ReduxStore from '../../database/redux-store';
import * as URIUtils from '../../utils/uri-utils';
// import {Spacer} from '../global';
// import Dialog from '../global/dialog';
// import Footer from '../global/footer';
// import Header from '../global/header';
import './payment-part-two-styles.css';

const PaymentPartOneScreen = (props) => {
  const navigate = useNavigate();
  let location = useLocation();

  const [name, setName] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const [county, setCounty] = useState('');
  const [cardholderName, setCardholderName] = useState();
  const [cardNumber, setCardNumber] = useState();

  const [id,setId] = useState(localStorage.getItem("id"))

  const [cardExpirationMonth, setCardExpirationMonth] = useState();
  const [cardExpirationYear, setCardExpirationYear] = useState();
  const [cardCvv, setCardCvv] = useState();

  const [showLoadingDialog, setShowLoadingDialog] = useState(false);

  const [isAlertShow, setAlertShow] = useState(false);

  const [isAlertMessage, setAlertMessage] = useState("");

  const [videoId, serVideoId] = useState(0);
  var line_items = [];


  const [custName, setCustName] = useState(URIUtils.getQueryParamValue(location, 'name'))
  const [custEmail, setcustEmail] = useState(URIUtils.getQueryParamValue(location, 'emailAddress'))

  useEffect(() => {

    let currCartItemsArray = [];

    if (typeof ReduxStore.getState().cache.checkout.cartItems !== 'undefined') {
      for (const [key, value] of Object.entries(ReduxStore.getState().cache.checkout.cartItems)) {
        if (typeof value !== 'undefined' && value !== null) {
          currCartItemsArray.push(value);
          line_items.push({
            variant_id: value.variant.id,
            quantity: value.quantity
          })
        }
      }
    }
    console.log(line_items);
    if (currCartItemsArray.length > 0) {
      for (var i = 0; i < currCartItemsArray.length; i++) {
        let newId = currCartItemsArray[i].videoId
        serVideoId(newId)
      }
    }

    let name = URIUtils.getQueryParamValue(location, 'name');
    let streetAddr = URIUtils.getQueryParamValue(location, 'streetAddress');
    let city = URIUtils.getQueryParamValue(location, 'city');
    let postalCode = URIUtils.getQueryParamValue(location, 'postalCode');
    let country = URIUtils.getQueryParamValue(location, 'country');
    let county = URIUtils.getQueryParamValue(location, 'county');
    let email = URIUtils.getQueryParamValue(location, 'emailAddress');

    let isAddressSame = URIUtils.getQueryParamValue(location, 'isAddressSame');



    if (isAddressSame == 'true') {
      setStreetAddress(URIUtils.getQueryParamValue(location, 'streetAddress'))
      setCity(URIUtils.getQueryParamValue(location, 'city'))
      setPostalCode(URIUtils.getQueryParamValue(location, 'postalCode'))
      setCounty(URIUtils.getQueryParamValue(location, 'county'))
      setCountry(URIUtils.getQueryParamValue(location, 'country'))
    } else {
      setStreetAddress('')
      setCity('')
      setPostalCode('')
      setCounty('')
      setCountry('')
    }


  }, []);

  function createParameterString() {
    let paramString = '?name=';
    return paramString;
  }

  function onCardNumberChange(event) {
    var eventValue = event.target.value;
    if (eventValue.slice(-1) == 0) {
      setCardNumber(eventValue)
    } else {
      var value = parseInt(eventValue.slice(-1))
      if (!!value) {
        setCardNumber(eventValue)
      } else {
        eventValue = eventValue.slice(0, -1);
        setCardNumber(eventValue)
      }
    }
  }

  function onCardMonthChange(event) {
    var eventValue = event.target.value;
    if (eventValue.slice(-1) == 0) {
      setCardExpirationMonth(eventValue)
    } else {
      var value = parseInt(eventValue.slice(-1))
      if (!!value) {
        setCardExpirationMonth(eventValue)
      } else {
        eventValue = eventValue.slice(0, -1);
        setCardExpirationMonth(eventValue)
      }
    }
  }

  function onCardYearChange(event) {
    var eventValue = event.target.value;
    if (eventValue.slice(-1) == 0) {
      setCardExpirationYear(eventValue)
    } else {
      var value = parseInt(eventValue.slice(-1))
      if (!!value) {
        setCardExpirationYear(eventValue)
      } else {
        eventValue = eventValue.slice(0, -1);
        setCardExpirationYear(eventValue)
      }
    }
  }


  function onCVVChange(event) {
    var eventValue = event.target.value;
    if (eventValue.slice(-1) == 0) {
      setCardCvv(eventValue)
    } else {
      var value = parseInt(eventValue.slice(-1))
      if (!!value) {
        setCardCvv(eventValue)
      } else {
        eventValue = eventValue.slice(0, -1);
        setCardCvv(eventValue)
      }
    }
  }


  return (<div
    className='screen-conainer-payment-part-one'>
    <div className='heading-grid'>
      <Link className=' mr-2' style={{ textDecoration: 'none', color: 'white' }} to={`/video/${id}`}><i class="fa-solid fa-chevron-left"></i></Link>
      <h4 className='text-center text-white  mt-2'>Payment</h4>
    </div>
    {
      /* LoadingOverlay no longer available
             showLoadingDialog && (
               <LoadingOverlay
                 className='loading-overlay'
                 active={true}
                 spinner
                 text='Making payment...'
                 >
                 <p>Some content or children or something.</p>
               </LoadingOverlay>
             )
           */
    }
    <div className='main-content header-checkout' >
      <div className='label-text' > Billing address </div>

      <form className='main-input' >
        <
          input className='main-input-field'
          type="text"
          name="name"
          placeholder="Street"
          defaultValue={
            streetAddress
          }
          onChange={
            (event) => {
              setStreetAddress(event.target.value);
            }
          }
        /> <label> Street </label> </form > <form className='main-input' >
        <
          input className='main-input-field'
          type="text"
          name="name"
          placeholder="City"
          defaultValue={
            city
          }
          onChange={
            (event) => {
              setCity(event.target.value);
            }
          }
        /> <
          label > City </label> </form> <form className='main-input' >
        <input className='main-input-field'
          type="text"
          name="name"
          placeholder="County"
          defaultValue={
            county
          }
          onChange={
            (event) => {
              setCounty(event.target.value);
            }
          }
        /> <label > County </label>
      </form> <form className='main-input' >
        <input className='main-input-field'
          type="text"
          name="name"
          placeholder="Post-Code"
          defaultValue={
            postalCode
          }
          onChange={
            (event) => {
              setPostalCode(event.target.value);
            }
          }
        /> <label> Post - Code </label> </form > <form className='main-input' >
        <input className='main-input-field'
          type="text"
          name="name"
          placeholder="Country"
          defaultValue={
            country
          }
          onChange={
            (event) => {
              setCountry(event.target.value);
            }
          }
        /> <label> Country </label>
      </form> {
      }

    </div> <div className='bottom-section-options' >
        <h5 className='text-white text-center mb-3'> Total: £{
          URIUtils.getQueryParamValue(location, 'total')
        }</h5>

      <button className='btn btn-red-color continue'
        onClick={
          () => {
            if (typeof streetAddress === 'undefined' || streetAddress === '' ||
              typeof city === 'undefined' || city === '' ||
              typeof country === 'undefined' || country === '' ||
              typeof postalCode === 'undefined' || postalCode === '') {
              // typeof cardholderName === 'undefined' || cardholderName === '' ||
              // typeof cardNumber === 'undefined' || cardNumber === '' ||
              // typeof cardExpirationMonth === 'undefined' || cardExpirationMonth === '' ||
              // typeof cardExpirationYear === 'undefined' || cardExpirationYear === '' ||
              // typeof cardCvv === 'undefined' || cardCvv === '') {
              // alert('Please fill out all fields.');
              setAlertShow(true)
              setAlertMessage("Please fill out all required fields.")
              return;
            }
            setShowLoadingDialog(true);
            let storeSubdomain = ReduxStore.getState().cache.checkout.storeSubdomain
            console.log("======", storeSubdomain)

            let link = "http://" + storeSubdomain + "/cart/"

            if (typeof ReduxStore.getState().cache.checkout.cartItems !== 'undefined') {
              for (const [key, value] of Object.entries(ReduxStore.getState().cache.checkout.cartItems)) {
                console.log("inside loop");
                if (typeof value !== 'undefined' && value !== null) {

                  link = link + value.variant.id + ":" + value.quantity + ","
                  // line_items.push({
                  //   variant_id: value.variant.id,
                  //   quantity: value.quantity
                  // })
                }
              }
            }
            link = link.substring(0, link.length - 1)
       
            link = link + "?checkout[email]=" + custEmail + "&checkout[shipping_address][first_name]=" + custName + "&checkout[shipping_address][city]=" + city + "&checkout[shipping_address][address1]=" + streetAddress + "&checkout[shipping_address][country]=" + country + "&checkout[shipping_address][zip]=" + postalCode
            console.log(link)
            window.open(
             link, '_blank'
            )


            // var config = {
            //   method: 'post',
            //   url: 'https://imursif.herokuapp.com/createOrder',
            //   // url: 'http://localhost:5559/createOrder',
            //   headers: {},
            //   data: {
            //     draft_order: {
            //       line_items: line_items,
            //       customer: {
            //         first_name: custName,
            //         last_name: "Testing",
            //         email: custEmail
            //       },
            //       billing_address: {
            //         first_name: custName,
            //         last_name: "Testing",
            //         address1: streetAddress,
            //         phone: "777-777-7777",
            //         city: county,
            //         province: county,
            //         country: country,
            //         zip: postalCode
            //       },
            //       shipping_address: {
            //         first_name: custName,
            //         last_name: "Testing",
            //         address1: streetAddress,
            //         phone: 777 - 777 - 7777,
            //         city: county,
            //         province: county,
            //         country: country,
            //         zip: postalCode
            //       },
            //       email: custEmail,
            //       financial_status: "pending"
            //     }
            //   }
            // };
            // console.log(config);

            // axios(config)
            //   .then(function (response) {


            //     let draftOrder = JSON.parse(response.data.body)

            //     // window.location.href = draftOrder.draft_order.invoice_url
            //     window.open(
            //       draftOrder.draft_order.invoice_url, '_blank'
            //     )
            //   })
            //   .catch(function (error) {
            //     console.log(error);
            //   });
          }
        }>Submit Payment </button>
    </div>
    {
      isAlertShow == true &&
      <div className='modal-dialog-dtff' >
        <div class="dialog success" >
          <button id="success" onClick={() => { setAlertShow(false) }
          }
            class="close" > x </button> <p> {
              isAlertMessage
            } </p>
        </div>
      </div>
    }

  </div>

  );
}

export default PaymentPartOneScreen;