/**
 * File:
 *   cache-reducer-actions.js
 */

const Checkout = {
  
  setCartItems: (cartItems,storeSubdomain) => {

    console.log("======>", cartItems,storeSubdomain);
    return {
      type: 'CHECKOUT_O_SET_CART_ITEMS',
      cartItems: cartItems,
      storeSubdomain:storeSubdomain,
     
    };
  },
  setVideoId:(videoId)=>{
    return{
      type: 'SET_VIDEO_ID',
      videoId:videoId
    }
  }
}

export default {
  Checkout,
};
