import React, { useEffect, useState, useRef } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useNavigate } from "react-router-dom";
import "../global/general.css";
import "./media.css";
import "./video-styles.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import ReducerActions from "../../database/actions/reducer-actions";
import ReduxStore from "../../database/redux-store";
import { Modal } from "../../global/modal.js";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import Cart from '../../assets/images/cart.svg';
import { baseUrl, getLinkedProducts } from "../../constants/config";
import Cross from "../../assets/images/cross.svg";
import { ThreeDots } from 'react-loader-spinner';


const VideoPlayer = (props) => {
    const navigate = useNavigate();
    // Declare a new state variable with the "useState" Hook
    const [width, setWidth] = React.useState(window.innerWidth);

    const [indicator, setIndicator] = useState(false)

    const [totalItems, setTotalItems] = useState(0)

    const [dimensions, setDimensions] = useState({
        height: '',
        width: ''
    })

    const [productVariantDropdownItems, setProductVariantDropdownItems] =
        useState([]);
    const [
        productVariantDropdownSelectedItems,
        setProductVariantDropdownSelectedItems,
    ] = useState();
    const [cartItems, setCartItems] = useState([]);
    const [requestProductsResponse, setRequestProductsResponse] = useState();
    const [showModal, setShowModal] = useState(false);

    const [openStore, setOpenStore] = useState(true);
    const [isClassChange, setClassChange] = useState(false);
    const [effectLogs, setEffectLogs] = useState([]);

    const [isAlertShow, setAlertShow] = useState(false);
    const [isAlertMessage, setAlertMessage] = useState("");
    const [shopName, setShopName] = useState("")
    const [showModalSelectedProductId, setShowModalSelectedProductId] =
        useState();

    const [isVideoIsReadyForDisplay, setIsVideoIsReadyForDisplay] =
        useState(false);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [showCheckoutReviewScreen, setShowCheckoutReviewScreen] =
        useState(false);
    const [myVideos, setMyVideos] = useState(null);

    const [ids, setId] = useState('')

    const { id } = useParams()

    const [dropDown, setDropDown] = useState();
    const [optionItems, setOptionItems] = useState([])
    const ref = useRef()
    const [singleProduct, setSingleproduct] = useState(null)
    const carouselRef = useRef()

    useEffect(() => {

        // if (id != null || id != 0) {
        //     localStorage.setItem("id", id)
        //     setId(id)
        // } else {
        //     let id = localStorage.getItem("id", id)
        //     setId(id)
        // }

        getToken(id);
        let currCartItems = ReduxStore.getState().cache.checkout.cartItems;
        if (typeof currCartItems !== "undefined") {
            let array = [];
            let total = 0
            for (const [key, value] of Object.entries(currCartItems)) {
                if (typeof value !== "undefined" && value !== null) {
                    total += value.quantity
                    array.push(value);
                }
            }
            setTotalItems(total)
            setCartItems(array);
        }

        ReduxStore.dispatch(
            ReducerActions.AppState.Screens.VideoPlayer.setFunctions(setCartItems)
        );

        // setClassChange(prevEffectLogs => [...prevEffectLogs, 'effect fn has been invoked'])
    }, []);

    const handleChange = (e) => {
        console.log("Hello", e);
        var value = e.currentTarget.value
        let newValue = {
            ...productVariantDropdownSelectedItems,
            [singleProduct.id]: JSON.parse(value),
        };

        setProductVariantDropdownSelectedItems(newValue);
        ref.current.click()
        carouselRef.current.prev()
    };



    async function getToken() {
        setIndicator(true)
        var data = JSON.stringify({
            query: `mutation{
          loginAsAnonymous
      }`,
            variables: {},
        });

        var config = {
            method: "post",
            url: baseUrl,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                let token = response.data.data.loginAsAnonymous;
                getVideosAgain(token);
            })
            .catch(function (error) {
                console.log(error);
                setIndicator(false)
            });
    }

    function getVideoDimensionsOf(url) {

        return new Promise(resolve => {
            // create the video element
            const video = document.createElement('video');

            // place a listener on it
            video.addEventListener("loadedmetadata", function () {
                // retrieve dimensions
                const height = this.videoHeight;
                const width = this.videoWidth;

                setDimensions({
                    ...dimensions,
                    height: height,
                    width: width
                })
                // send back result
                resolve({ height, width });
            }, false);

            // start download meta-datas
            video.src = url;
        });
    }

    async function getVideosAgain(token) {
        var data = {
            "id": id
        }
        console.log(id)
        var config = {
            method: "post",
            url: getLinkedProducts,
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                setMyVideos(response.data.data.video);
                getVideoDimensionsOf(response.data.data.video.url)
                let json = response.data;
                let prodVarntDropdownItems = {};
                let prodVarntDropdownSelctdItems = {};
                let newData = json.data.video.linkedProducts;

                if (newData.length > 0) {
                    setRequestProductsResponse(newData);
                }

                let productsData = [];
                for (var i = 0; i < newData.length; i++) {
                    let data = newData[i].product;
                    productsData.push(data);
                }

                for (const product of productsData) {
                    let variants = product.variants;

                    let productVariantDropdownSubItems = [];
                    for (const variant of variants) {
                        productVariantDropdownSubItems.push({
                            key: variant.title,
                            label: variant.title,
                            productVariant: variant,
                        });
                    }
                    prodVarntDropdownItems = {
                        ...prodVarntDropdownItems,
                        [product.id]: productVariantDropdownSubItems,
                    };

                    prodVarntDropdownSelctdItems = {
                        ...prodVarntDropdownSelctdItems,
                        [product.id]: {
                            key: variants[0].title,
                            label: variants[0].title,
                            productVariant: variants[0],
                        },
                    };
                }
                setProductVariantDropdownItems(prodVarntDropdownItems);
                setProductVariantDropdownSelectedItems(prodVarntDropdownSelctdItems);
                setIsVideoIsReadyForDisplay(true)
                setIndicator(false)
            })
            .catch(function (error) {
                console.log(error);
                setIndicator(false)
            });
    }

    const handleDropDownList = (items) => {
        let variants = productVariantDropdownItems[items.id]
        let options = variants.map((item) => (
            <option value={JSON.stringify(item)} key={item.id}>
                {item.label}
            </option>
        ))
        setOptionItems(options)
        setSingleproduct(items)
    };


    const OverlayItem = ({ listItemObject }) => {

        return (
            <div class="item d-inline-block">
                <div class={dimensions.height > dimensions.width ? "bottom-product" : "bottom-product-land"}>
                    <div class="bottom-product-grid">
                        <img src={listItemObject.image} alt="" />
                        <div>
                            <div class="d-flex align-items-start justify-content-between mb-1">
                                <p class="productname mb-0">{listItemObject.title} </p>
                                <p class="productprice mb-0">
                                    £ {listItemObject.variants[0].price}
                                </p>
                            </div>
                            <div class="grid-buy">
                                <button
                                    style={{ backgroundColor: '#fff' }}
                                    disabled={listItemObject.variants.length <= 1 && true}
                                    onClick={
                                        () => {
                                            handleDropDownList(listItemObject)
                                        }
                                    }
                                    class="select-variant-btn"
                                    data-toggle="modal"
                                    data-target="#Variant-modal"
                                >
                                    {productVariantDropdownSelectedItems[listItemObject.id].label}
                                    <i class="fa-solid fa-caret-left ml-1"></i>
                                </button>

                                <button
                                    className="right-button buy-btn"
                                    onClick={() => {
                                        let currCartItems =
                                            ReduxStore.getState().cache.checkout.cartItems;
                                        let currCartItemsArray = [];
                                        if (typeof currCartItems !== "undefined") {
                                            for (const [key, value] of Object.entries(currCartItems)) {
                                                if (typeof value !== "undefined" && value !== null) {
                                                    currCartItemsArray.push(value);
                                                }
                                            }
                                        }
                                        let newCartItems;
                                        let randNo = Math.floor(Math.random() * 10000) + 1; // 1 - 10000
                                        if (typeof currCartItems !== "undefined") {
                                            let contains = true;
                                            while (contains) {
                                                let containsId = false;
                                                for (const currCartItem of currCartItemsArray) {
                                                    if (currCartItem.id == randNo) {
                                                        containsId = true;
                                                        break;
                                                    }
                                                }
                                                if (containsId) {
                                                    randNo = Math.floor(Math.random() * 10000) + 1;
                                                }
                                                contains = containsId;
                                            }
                                        }
                                        let newCartItemsItem = {
                                            id: randNo,
                                            productId: listItemObject.id,
                                            productTitle: listItemObject.title,
                                            productImage: listItemObject.image,
                                            quantity: 1,
                                            videoId: id,
                                            variant: {
                                                ...productVariantDropdownSelectedItems[listItemObject.id]
                                                    .productVariant,
                                            },
                                        };
                                        newCartItems = {
                                            ...currCartItems,
                                            [randNo]: newCartItemsItem,
                                        };

                                        let array = [];
                                        for (const [key, value] of Object.entries(newCartItems)) {
                                            if (typeof value !== "undefined" && value !== null) {
                                                array.push(value);
                                            }
                                        }

                                        let match = 0;
                                        cartItems.forEach((element, index) => {
                                            if (element.productId == newCartItemsItem.productId && element.variant.id == newCartItemsItem.variant.id) {
                                                cartItems[index].quantity = cartItems[index].quantity + 1;
                                                match = 1;

                                            }
                                        });
                                        if (match == 0) {
                                            console.log("item2")
                                            ReduxStore.dispatch(
                                                ReducerActions.Cache.Checkout.setCartItems(newCartItems, myVideos.user.shopifyApiInfo.storeSubdomain)
                                            );

                                            let newCart = ReduxStore.getState().cache.checkout.cartItems;
                                            if (typeof newCart !== "undefined") {
                                                let total = 0
                                                let array = [];
                                                for (const [key, value] of Object.entries(newCart)) {
                                                    if (typeof value !== "undefined" && value !== null) {
                                                        total += value.quantity
                                                        array.push(value);
                                                    }
                                                }
                                                setTotalItems(total)
                                            }


                                            setCartItems(array);
                                        } else {
                                            setTotalItems(totalItems + 1)
                                        }




                                    }}
                                >
                                    Add to cart
                                </button>


                            </div>
                        </div>
                    </div>
                </div>
            </div>


            // <div className="overlay-item-parent">
            //   <div className="overlay-item-child-background" />
            //   <div className="overlay-item-child">
            //     <div>
            //       <img
            //         style={{
            //           borderRadius: "16px",
            //           left: 1,
            //           width: 70,
            //           height: 70,
            //           marginRight: 12,
            //           padding: 6,
            //         }}
            //         src={listItemObject.image}
            //         alt="Image"
            //       />
            //     </div>
            //     <div className="right-section">
            //       <div className="top-row">
            //         <div className="left-text">{listItemObject.title}</div>
            //         <div className="middle-text"></div>
            //         <div className="right-text">
            //           £{listItemObject.variants[0].price}
            //         </div>
            //       </div>

            //       <div
            //         style={{
            //           height: "5px",
            //         }}
            //       ></div>

            //       <div className="bottom-row">
            //         <button
            //           className="left-button"
            //           onClick={() => {
            //             setShowModalSelectedProductId(listItemObject.id);
            //             setShowModal(true);
            //           }}
            //         >
            //           {productVariantDropdownSelectedItems[listItemObject.id].label}
            //         </button>

            //       </div>
            //     </div>
            //   </div>
            // </div>
        );
    };

    return (

        <>
            {
                indicator ?
                    <div className="top-content" style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }} >
                        <ThreeDots color="red" height={80} width={80} />
                    </div >
                    :

                    <div class={dimensions.width < dimensions.height ? "videowrapper-new" : "land-videowrapper-new"}>
                        <div class={dimensions.width < dimensions.height ? "videocontainer-new" : "land-videocontainer-new"}>
                            {showModal && (
                                <Modal
                                    className="modal"
                                    show={true}
                                    handleClose={() => {
                                        setShowModal(false);
                                    }}
                                >
                                    <div style={{ height: "10px" }} />
                                    {productVariantDropdownItems[showModalSelectedProductId].map(
                                        (item) => {
                                            return (
                                                <div>
                                                    <button
                                                        className="item"
                                                        onClick={() => {
                                                            let newValue = {
                                                                ...productVariantDropdownSelectedItems,
                                                                [showModalSelectedProductId]: item,
                                                            };
                                                            setProductVariantDropdownSelectedItems(newValue);
                                                            setShowModal(false);
                                                        }}
                                                    >
                                                        {item.label}
                                                    </button>
                                                </div>
                                            );
                                        }
                                    )}
                                    <div style={{ height: "4px" }} />
                                </Modal>
                            )}
                            {requestProductsResponse && (
                                <video
                                    autoPlay
                                    preload="metadata"
                                    playsInline={1}
                                    disablePictureInPicture={true}
                                    controlsList="nofullscreen nodownload noremoteplayback noplaybackrate foobar"
                                    controls
                                    src={myVideos?.url}></video>
                            )}
                            {isAlertShow == true && (
                                <div className="modal-dialog-dtff">
                                    <div className="dialog-success" >
                                        <p style={{ textAlign: 'center', marginBottom: 0 }}>{isAlertMessage}</p>
                                        <button
                                            onClick={() => setAlertShow(false)}
                                            id="success"
                                            className="close"
                                        >
                                            <img src={Cross} alt=""></img>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                        {isVideoIsReadyForDisplay && requestProductsResponse && (
                            <>
                                <div
                                    class={"overlay-bottom"}>
                                    <Swiper
                                        slidesPerView={"auto"}
                                        spaceBetween={10}
                                        className="mySwiper bottom-slider "
                                    >

                                        {requestProductsResponse.length > 0 &&
                                            requestProductsResponse.map((item, key) => (
                                                <SwiperSlide

                                                >
                                                    <OverlayItem key={key} listItemObject={item.product} />
                                                </SwiperSlide>
                                            ))}
                                    </Swiper>

                                    <div class="check-grid"><button onClick={() => {
                                        if (
                                            typeof cartItems === "undefined" ||
                                            cartItems.length === 0
                                        ) {
                                            // alert('No items in cart');
                                            setAlertShow(true);
                                            setAlertMessage("No items in cart");
                                        } else {
                                            navigate("/checkout-part-one", { state: { screenName: "video" } });
                                        }
                                    }} class="btn btn-red-color">Checkout</button>
                                        <button onClick={() => {
                                            if (
                                                typeof cartItems === "undefined" ||
                                                cartItems.length === 0
                                            ) {
                                                // alert('No items in cart');
                                                setAlertShow(true);
                                                setAlertMessage("No items in cart");
                                            } else {
                                                navigate("/checkout-part-one");
                                            }
                                        }}

                                            class="cartIcon-btn">
                                            <img src={Cart} /><p class="cart-number">{totalItems}</p>
                                        </button>
                                    </div>

                                </div>
                            </>
                        )}
                        <div
                            class="modal fade theme-modal custom-modal"
                            id="Variant-modal"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="Variant-modalLabel"
                            aria-hidden="true"
                        >
                            <div class="modal-dialog  modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <div class="custom-header">
                                        <h4>Select Variant </h4>
                                        <button
                                            ref={ref}
                                            type="button"
                                            class="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <img src={Cross} alt=""></img>
                                        </button>
                                    </div>
                                    <div class="modal-body px-md-5">
                                        <form action="">
                                            <div class="mb-4">
                                                <select
                                                    value={dropDown}
                                                    class="form-control"
                                                    onChange={handleChange}
                                                >
                                                    {optionItems}
                                                </select>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

            }
        </>
    );
};




export default VideoPlayer;
