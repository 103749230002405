/**
 * File:
 *   root-reducer.js
 * 
 * Description:
 *   The Redux root reducer
 */

import { combineReducers } from 'redux';
import AppStateReducer from './app-state-reducer';
import cacheReducer from './cache-reducer';
import ImursifRequestDataReducer from './imursif-request-data-reducer';
import ImursifRequestMetadataReducer from './imursif-request-metadata-reducer';

const rootReducer = combineReducers({
  
  appState: AppStateReducer,
  cache: cacheReducer,
  imursifRequestData: ImursifRequestDataReducer,
  imursifRequestMetadata: ImursifRequestMetadataReducer,
  
});

export default rootReducer;
