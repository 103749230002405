/**
 * File:
 *   cache-reducer.js
 */

/**
 * Get the default state.
 */
function getDefaultState() {
  return {
    checkout: {
      storeSubdomain:"",
      cartItems: undefined,
      id:""
    },
  };
}

const reducer = (state = getDefaultState(), action) => {

  

  switch (action.type) {
    case 'CHECKOUT_O_SET_CART_ITEMS':    
      return {
        ...state,
        checkout: {
          storeSubdomain:action.storeSubdomain,
          cartItems: {
            ...action.cartItems
          }
        }
      };
    case 'SET_VIDEO_ID' :
      return{
        ...state,
        checkout:{videoId:action.videoId}
      }
    default:
      return state;
  }
};

export default reducer;
