/**
 * File:
 *   reducer-actions.js
 * 
 * Description:
 *   All the reducer actions
 */

import AppStateReducerActions from './app-state-reducer-actions';
import CacheReducerActions from './cache-reducer-actions';
import ImursifRequestDataReducerActions from './imursif-request-data-reducer-actions';
import ImursifRequestMetadataReducerActions from './imursif-request-metadata-reducer-actions';

export default {
  
  AppState: AppStateReducerActions,
  Cache: CacheReducerActions,
  ImursifRequestData: ImursifRequestDataReducerActions,
  ImursifRequestMetadata: ImursifRequestMetadataReducerActions,
  
}
