/*
File:
  rest-constants.js
  
Description:
  REST constants
*/

const BASE_URL_PRODUCTION = 'https://imursif.herokuapp.com/graphql';
const BASE_URL_DEVELOPMENT = 'https://imursif.herokuapp.com/graphql';
const BASE_URL = BASE_URL_DEVELOPMENT;

export default {

  BASE_URL: BASE_URL,
  DEFAULT_REQUEST_HEADERS: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  HTTP_REQUEST_TIMEOUT_MS: 15000,

  Requests: {
    createAuthenticateRequestUri: () => {
      return '/?key=e977c561ad9967b967390a9813f9f7dd&request=login';
    },
  },

  ImursifMockRequestData: {

    AUTHENTICATE: { 'data': 'test' },

  }, // end ImursifMockRequestData

}
