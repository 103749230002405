/**
 * File:
 *   app-state-reducer.js
 */

/**
 * Get the default state.
 */
function getDefaultState() {
  return {
    general: {
      currentVisibleScreenName: undefined,
      currentVisibleViewName: undefined,
      user: {
        doesUserNeedToBeLoggedOut: false,
      },
    },
    backgroundTasks: {
      logIn: {
        isCheckTokenExpirationBackgroundTaskRunning: false,
      }
    },
    screens: {
      logIn: {
        setUpIsBiometricLogInEnabled: undefined,
      },
      videoPlayer: {
        setCartItems: undefined,
      },
    },
  };
}

const reducer = (state = getDefaultState(), action) => {
  switch (action.type) {
    case 'GENERAL_O_CURRENT_VISIBLE_SCREEN_NAME':
      return {
        ...state, general: { ...state.general,
          currentVisibleScreenName: action.currentVisibleScreenName,
          user: {
            ...state.general.user,
          }
        }
      };
    case 'GENERAL_O_CURRENT_VISIBLE_VIEW_NAME':
      return {
        ...state, general: { ...state.general,
          currentVisibleViewName: action.currentVisibleViewName,
          user: {
            ...state.general.user,
          }
        }
      };
    case 'GENERAL_S_USER_O_DOES_USER_NEED_TO_BE_LOGGED_OUT':
      return {
        ...state, general: { ...state.general,
          user: {
            ...state.general.user,
            doesUserNeedToBeLoggedOut: action.doesUserNeedToBeLoggedOut,
          }
        }
      };
    case 'GENERAL_S_USER_O_CLEAR_ALL':
      return {
        ...state, general: { ...state.general,
          user: {
            ...getDefaultState().general.user,
          }
        }
      };
    case 'BACKGROUND_TASKS_S_LOG_IN_O_IS_CHECK_TOKEN_EXPIRATION_BACKGROUND_TASK_RUNNING':
      return {
        ...state, backgroundTasks: { ...state.backgroundTasks,
          logIn: {
            ...state.backgroundTasks.logIn,
            isCheckTokenExpirationBackgroundTaskRunning:
                action.isCheckTokenExpirationBackgroundTaskRunning,
          }
        }
      };
    case 'SCREENS_S_LOG_IN_O_SET_FUNCTIONS':
      return {
        ...state, screens: { ...state.screens,
          logIn: {
            ...state.screens.logIn,
            setUpIsBiometricLogInEnabled: action.setUpIsBiometricLogInEnabled,
          }
        }
      };
    case 'SCREENS_S_VIDEO_PLAYER_O_SET_FUNCTIONS':
      return {
        ...state, screens: { ...state.screens,
          videoPlayer: {
            ...state.screens.videoPlayer,
            setCartItems: action.setCartItems,
            setForceRerenderValue: action.setForceRerenderValue,
          }
        }
      };
    default:
      return state;
  }
};

export default reducer;
