/**
 * File:
 *   imursif-request-data-reducer.js
 */

import {
   RestConstants,
 } from '../../rest';
import * as ReducerUtils from './reducer-utils';

/**
 * Get the default state.
 */
function getDefaultState() {
  let intlState = {};
  // for (var key of Object.keys(RestConstants.Requests)) {
  //   let keyNameCamelCase = ReducerUtils.convertRouteToCamelCasedName(key);
  //   intlState[RestConstants.Requests[keyNameCamelCase]] = { };
  // }
  return intlState;
}

/**
 * The reducer function
 * @param {Object} state     The state passed by Redux; Note: We give it an
 *                           initial value so we don't have to check for
 *                           certain keys throughout the app.
 * @param {Object} action    The action passed by Redux
 */
const reducer = (state = getDefaultState(), action) => {
  switch (action.type) {
    case 'SET_DATA':
      let requestNameCamelCase = ReducerUtils.convertRouteToCamelCasedName(
          action.request);
      return {
        ...state,
        [requestNameCamelCase]: {
          ...action.data,
        },
      };
    case 'CLEAR_DATA':
      requestNameCamelCase = ReducerUtils.convertRouteToCamelCasedName(
          action.request);
      return {
        ...state,
        [requestNameCamelCase]: { },
      };
    default:
      return state;
  }
};

export default reducer;
