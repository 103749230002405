/**
 * File:
 * 
 * Description:
 *   The Redux store
 */

import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import RootReducer from './reducers/root-reducer';

const reduxLogger = store => next => action => {
  console.group(action.type);
  console.info('dispatching', action);
  let result = next(action);
  console.log('next state', store.getState());
  console.groupEnd();
  return result;
}

const middlewares = [ reduxLogger ];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [ middlewareEnhancer ];
const composedEnhancers = composeWithDevTools(...enhancers);

const reduxStore = createStore(RootReducer);

export default reduxStore;
