/**
 * File:
 *   async-storage-keys.js
 * 
 * Description:
 *   The AsyncStorage keys
 */

export default {
  
  Cache: {
  
    userEmailAddress: '@Cache:userEmailAddress',
    enableRememberMe: '@Cache:enableRememberMe',
    
  },
  
  Settings: {
  
    isBiometricLogInEnabled: '@Settings:isBiometricLogInEnabled',
    biometricLogInUserEmail: '@Settings:biometricLogInUserEmail',
    biometricLogInUserPassword: '@Settings:biometricLogInUserPassword',

  },
  
  ServerData: {
  
    websocketUserToken: '@ServerData:websocketUserToken',
    messages: '@ServerData:messages',
    
  },
  
}

