/**
 * File:
 *   imursif-request-metadata-reducer-actions.js
 */

const setMadeAt = (request, madeAt, wasSuccessful) => {
  return {
    type: 'SET_MADE_AT',
    request: request,
    madeAt: madeAt,
    wasSuccessful: wasSuccessful,
  };
};

const setIsCurrentlyBeingMade = (request, isCurrentlyBeingMade) => {
  return {
    type: 'SET_IS_CURRENTLY_BEING_MADE',
    request: request,
    isCurrentlyBeingMade: isCurrentlyBeingMade,
  };
};

const clearMadeAt = (request) => {
  return {
    type: 'CLEAR_MADE_AT',
    request: request,
  };
};

export default {
  setMadeAt,
  setIsCurrentlyBeingMade,
  clearMadeAt,
};
